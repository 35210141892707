.modal {
    --body-overflow: visible;
}
.errorAlert {
    margin-top: 3%;
}

.responseMessage {
    margin-top: 1.5em;
}

.entryPointMessage {
    font-size: 1.3em;

}
.linkDiv {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.copyDiv {
    margin-left: 0.5rem;
    align-items : flex-start;
    flex-basis: auto;
}

.principalButtonDisabled:hover {
    cursor : no-drop;
}

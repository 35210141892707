.entryPointMessageBox {
    margin-left: 1rem;
    display: flex;
    justify-content: flex-start;
}
.copyDiv {
    margin-left: 0.5rem;
}
.entryPointMessage {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4em;
    margin: .83em 0
}

.displayDataTable{
    flex-basis: 75%;
    flex-grow: 2;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 1rem;

}
.displayFlexContainer{
    margin-left: 0.5rem;
    margin-top: 1em;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items : flex-end;
}
.buttonContainer {
    display: flex;
    justify-content: center;
    flex-basis: 25%;
    flex-grow: 1;
    padding-bottom: 2.2rem;
}

.errorAlertContainer {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    align-content: center;
}
.errorAlert {
    flex-basis: 50%;
}

